.home-wrapper {
    margin: 2vmax;
    /* height: 70vh; */
    background-color: white !important;
    border-radius: 0.75rem;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    padding-top: 1vh;
}

.hero-title-wrapper {
    text-align: center;
    margin-bottom: 2vh;
    font-size: 5vmax;
    line-height: 100%;
    margin-top: 2vh !important;
    padding: 0 2vh;
}

.hero-subtitle-wrapper {
    font-size: medium;
    font-family: 'ProductSans-Regular', serif;
    text-align: center;
    width: fit-content;
    margin: auto;
    padding: 0.5vmax;
    border-radius: 0.75rem;
    background: #ffda9b;
    animation: genai-transition 5s infinite;
    color: white;
}

.info-bar-wrapper {
    margin-top: 2vh;
    text-align: center;
    padding: 1vh 2vw;
    background: #ff8a86;
    color: white;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.input-wrapper {
    margin-top: 4vh;
}

.input-row-wrapper {
    margin: 0 !important;
}

.details-wrapper {
    text-align: center;
    margin-top: 2vh;
}

.details-wrapper>input {
    border-radius: 0.75rem;
    border: none;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    transition: 0.3s;
    text-align: center;
    font-size: large;
    width: 80%;
}

.details-wrapper>input:hover {
    outline: 0.2rem solid #ff8a86;
    color: #ff8a86;
}

.submit-wrapper {
    margin-top: 3vh;
    text-align: center;
    font-size: large;
}

.submit-wrapper>button {
    border: none;
    border-radius: 0.75rem;
    background-color: rgb(128, 211, 128);
    color: white;
    padding: 0 1vh;
    transition: 0.3s;
}

.submit-wrapper>button:hover {
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    color: rgb(128, 211, 128);
    background-color: white;
}

.footer-wrapper {
    margin-top: 3vh;
    text-align: center;
    font-size: medium;
    padding: 1%;
    background-color: #36454F;
    color: white;
    transition: 0.3s;
}

.footer-wrapper:hover{
    color: #36454F;
    background-color: white;
}

@keyframes genai-transition {
    0% {
        background-color: #ffda9b;
    }

    50% {
        background-color: #ff8a86;
    }

    100% {
        background-color: #ffda9b;
    }
}