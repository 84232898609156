@font-face {
  font-family: 'ProductSans-Bold';
  src: local('ProductSans-Bold'), url(./fonts/ProductSans-Bold.ttf) format('truetype');
}

@font-face {
  font-family: 'ProductSans-Medium';
  src: local('ProductSans-Medium'), url(./fonts/ProductSans-Medium.ttf) format('truetype');
}

@font-face {
  font-family: 'ProductSans-Thin';
  src: local('ProductSans-Thin'), url(./fonts/ProductSans-Thin.ttf) format('truetype');
}

@font-face {
  font-family: 'ProductSans-Regular';
  src: local('ProductSans-Regular'), url(./fonts/ProductSans-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'ProductSans-Light';
  src: local('ProductSans-Light'), url(./fonts/ProductSans-Light.ttf) format('truetype');
}

* {
  font-family: 'ProductSans-Medium', serif;
  text-rendering: optimizeLegibility !important;
  -webkit-font-smoothing: antialiased !important;
  -moz-osx-font-smoothing: grayscale !important;
  -webkit-transform: translate3d(0, 0, 0);
  color: #36454F;
  font-weight: 100;
  /* background-color: #202125; */
}


body {
  /* background: linear-gradient(to right, #ff8a86, #ffda9b);
  background: -webkit-linear-gradient(left, #ff8a86, #ffda9b);
  background: -moz-linear-gradient(left, #ff8a86, #ffda9b);
  background: -o-linear-gradient(left, #ff8a86, #ffda9b);
  background: linear-gradient(to right, #ff8a86, #ffda9b); */
  display: flex;
  background: linear-gradient(to right, #36454F, #36454F);
  flex-direction: column; 
  justify-content: center;
  align-items: center;
  height: 100vh; 
  width: 100%; 
}